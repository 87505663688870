// globalStyles.js
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');


 html{
  margin:0;
  padding:0;
} 
  body {
    background: #282828;
    color:#fcfcfc;
    line-height:1.3;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight:400;
  }
  h1, h2, h3, h4,h5,h6{
    font-family: 'Open Sans', sans-serif;
    line-height:1.4; 
  }
  h1{
    line-height:1.4; 
  }
  .test{
    width:100px;
    height:100px;
    background-color:red;
  }
`

export default GlobalStyle
